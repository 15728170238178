import { forEach, initial } from "lodash";
import splide, { customSplide } from "../utils/splide";

const banner = document.getElementById("banner");
if (banner) {
    const bannerSplide = customSplide(banner, {
        type: "fade",
        rewind: true,
        perPage: 1,
        autoplay: true,
        fixedHeight: "656px",
        speed: 2000,
        lazyLoad: "sequential",
        pagination: true,
        arrows: banner.dataset.slides > 1 ? true : false,
        breakpoints: {
            1200: {
                pagination: true,
                arrows: false,
            },
        },
    });
    bannerSplide.mount();
}

const about = document.getElementById("about");
if (about) {
    const aboutSplide = splide(about, {
        perPage: 1,
        breakpoints: {},
    });
    aboutSplide.mount();
}

const news = document.getElementById("news");
if (news) {
    const newsSplide = splide(news, {
        perPage: 3,
        arrows: true,
        breakpoints: {
            1200: {
                perPage: 2,
                arrows: false,
                pagination: true,
            },
            600: {
                perPage: 1,
            },
        },
    });
    newsSplide.mount();
}

const partners = document.getElementById("partners");
if (partners) {
    const partnerSplide = splide(partners, {
        perPage: 3,
        breakpoints: {
            1200: {
                perPage: 3,
                arrows: false,
                pagination: true,
            },
            600: {
                perPage: 1,
            },
        },
    });
    partnerSplide.mount();
}

const service = document.getElementById("services");
if (service) {
    const serviceSplide = splide(service, {
        perPage: 3,
        breakpoints: {
            1200: {
                perPage: 2,
                arrows: true,
                pagination: true,
            },
            600: {
                perPage: 1,
                arrows: false,
                pagination: true,
            },
        },
    });
    serviceSplide.mount();
}
const value = document.getElementById("values");
if (value) {
    const valueSplide = splide(value, {
        perPage: 4,
        arrows: true,
        breakpoints: {
            1200: {
                perPage: 3,
                arrows: true,
                pagination: true,
            },
            600: {
                perPage: 2,
                arrows: false,
                pagination: true,
            },
        },
    });
    valueSplide.mount();
}

const deposition = document.getElementById("depositions");
if (deposition) {
    const depositionSplide = splide(deposition, {
        perPage: 2,
        arrows: true,
        breakpoints: {
            1200: {
                perPage: 2,
                arrows: true,
                pagination: true,
            },
            600: {
                perPage: 1,
                arrows: false,
                pagination: true,
            },
        },
    });
    depositionSplide.mount();
}
const datas = document.querySelectorAll(".counter");
window.addEventListener("scroll", async () => {

    datas.forEach((data) => {
        let initialValue = +(data.innerHTML);
        let endValue = +(data.getAttribute("label"));
        if (initialValue < endValue && initialValue <= 0) {
            let duration = 3500 / (endValue - 1);
            if (data.getBoundingClientRect().top - window.innerHeight <= -(data.getBoundingClientRect().height)) {
                data.innerHTML = initialValue + 1;
                const intervalor = setInterval(() => {
                    let quantity = (endValue / 1500 > 1.5) ? endValue / 500 : 1;
                    let c = 1;
                    while (quantity >= c) {
                        let result = addCounter(data);
                        if (!result) {
                            clearInterval(intervalor);
                        }
                        c++;
                    }
                }, duration);
            }
        }
    });
});

function addCounter(el) {
    let initial = +(el.innerHTML);
    let end = +(el.getAttribute("label"));

    if (initial < end) {
        initial += 1;
    } else {
        return false;
    }
    el.innerHTML = initial.toString();
    return true;
}
